// extracted by mini-css-extract-plugin
export var card = "EBikes-module--card--d4c54";
export var cardContainer = "EBikes-module--cardContainer--e481a";
export var container = "EBikes-module--container--7f5e9";
export var containerDesc = "EBikes-module--containerDesc--523c1";
export var containerTitle = "EBikes-module--containerTitle--29444";
export var desc = "EBikes-module--desc--c9470";
export var image = "EBikes-module--image--22eb0";
export var other = "EBikes-module--other--4aea5";
export var price = "EBikes-module--price--9174b";
export var separator = "EBikes-module--separator--06e68";
export var title = "EBikes-module--title--cae5c";