import React, { useEffect } from 'react';
import { navigate } from 'gatsby-link';
import { StaticImage } from 'gatsby-plugin-image';

import products from '@data/ebikes.json';

import Button from '@components/Button/Button';

import * as styles from './EBikes.module.css';

const EBikes = ({ detail, en }) => {
  const onClick = product => {
    navigate(product.url);
  };

  const handleJSONld = products => {
    if (typeof document !== 'undefined') {
      products.forEach(p => {
        const images = p.images.map(i => {
          const image = require(`@static/img/${i}`).default;
          return `https://www.tizianobiellersport.com${image}`;
        });
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.textContent = JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'Product',
          name: !en
            ? `Noleggio E-Bike ${p.name} a Champoluc - Valle d'Aosta`
            : `${p.name} E-Bike Rental in Champoluc - Valle d'Aosta`,
          description: `${p.description.substr(0, 120)}...`,
          image: images,
          brand: 'E-Bike - Tiziano Bieller Sport',
          category: !en ? 'Noleggio E-Bike' : 'E-Bike Rental',
          model: p.name,
          review: [
            {
              '@type': 'Review',
              reviewRating: {
                '@type': 'Rating',
                ratingValue: '5',
              },
              author: {
                '@type': 'Person',
                name: 'Seb Quish',
              },
            },
            {
              '@type': 'Review',
              reviewRating: {
                '@type': 'Rating',
                ratingValue: '5',
              },
              author: {
                '@type': 'Person',
                name: 'Alessandro Conca',
              },
            },
            {
              '@type': 'Review',
              reviewRating: {
                '@type': 'Rating',
                ratingValue: '5',
              },
              author: {
                '@type': 'Person',
                name: 'Andrea Bottin',
              },
            },
          ],
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '4.6',
            bestRating: '5',
            ratingCount: '27',
            reviewCount: '27',
            worstRating: '1',
          },
          offers: {
            '@type': 'Offer',
            url: `https://www.tizianobiellersport.com${p.url}`,
            priceCurrency: 'EUR',
            price: p.prices.full.toFixed(2).toString(),
            availability: 'https://schema.org/InStock',
          },
        });

        document.head.appendChild(script);

        return true;
      });
    }
  };

  const renderEBikes = () => {
    return products.data
      .filter(p => (!en ? p.lang === 'it' : p.lang === 'en'))
      .map(product => {
        let desc =
          product.id === '0001' || product.id === '0003'
            ? 'E-Bike da adulto adatta a tutti, ruote da 29" e 27.5", forcella anteriore da 170mm e batteria da 720Wh.'
            : 'E-Bike da bambino facile e sicura, ruote da 24", forcella da 80mm, batteria da 400Wh.';
        if (en) {
          desc =
            product.id === '0001' || product.id === '0003'
              ? 'Adult E-Bike for everyone, 29" e 27.5" wheels, 170mm Front Fork + 720Wh Battery.'
              : 'Kids E-Bike easy and safe, 24" wheels, 80mm Front Form, 400Wh Battery.';
        }
        return (
          <a
            href={`#${product.id}`}
            key={product.id}
            className={styles.cardContainer}
            onClick={e => {
              e.preventDefault();
              onClick(product);
            }}
          >
            <div className={styles.card}>
              {product.id === '0001' || product.id === '0003' ? (
                <StaticImage
                  src="../../static/img/ebike/haibike.webp"
                  className={styles.image}
                  alt={product.name}
                  placeholder="blurred"
                  width={426}
                  height={355}
                />
              ) : (
                <StaticImage
                  src="../../static/img/ebike/haibike-bambino.webp"
                  className={styles.image}
                  alt={product.name}
                  placeholder="blurred"
                  width={426}
                  height={355}
                />
              )}

              <h3 className={styles.title}>{product.name}</h3>
              <p className={styles.desc}>{desc}</p>
              <Button
                onClick={() => {
                  onClick(product);
                }}
                full
              >
                SCOPRI DI PIÙ
              </Button>
            </div>
          </a>
        );
      });
  };

  useEffect(() => {
    handleJSONld(products.data);
  }, []);

  return (
    <div className={styles.container}>
      {detail ? (
        <h1 className={styles.containerTitle}>
          {!en ? 'Le E-Bike a Noleggio' : 'E-Bike Rental'}
        </h1>
      ) : (
        <h2 className={styles.containerTitle}>
          {!en ? 'Le E-Bike a Noleggio' : 'E-Bike Rental'}
        </h2>
      )}
      {detail && (
        <p className={styles.containerDesc}>
          {!en
            ? 'E-Bike da bambino con ruote da 24" e da Adulto (taglie S/M/L) con ruote da 29" e 27.5".'
            : 'E-Bike for children with 24" wheels and for Adults (sizes S/M/L) with 29" and 27.5" wheels.'}
        </p>
      )}

      {renderEBikes()}

      <div className={styles.other}>
        <strong>
          {!en
            ? 'Sei interessato ad una soluzione di noleggio per 1 ora, 2 ore o mezza giornata?'
            : 'Are you interested in a rental solution for 1 hour, 2 hours or half a day?'}
        </strong>
        <br />
        {!en ? 'Contattaci subito via email' : 'Contact us by email'}{' '}
        <a href="mailto:info@tizianobiellersport.com">
          info@tizianobiellersport.com
        </a>{' '}
        {!en ? 'o telefonicamente' : 'or by phone'}{' '}
        <a href="tel:3394329988">339.4329988</a> -{' '}
        <a href="tel:0125308111">0125.308111</a>
      </div>
    </div>
  );
};

export default EBikes;
